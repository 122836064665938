import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Paragraph, Button, Heading } from "../components"

export const Teaser = () => {
  return (
    <section className="flex flex-col sm:py-16 lg:flex-row">
      <div className="z-10 flex-shrink flex-grow basis-full p-5 sm:p-20">
        <Heading level={1} className="sm:text-5xl">
          Launch Your Own NFT Store in Minutes.
        </Heading>
        <Paragraph level={1} className="my-10">
          Grow your NFT brand with zero technical expertise, creator &
          enterprise friendly features, complete customisation, and tons of
          integrations!
        </Paragraph>
        <Button
          onClick={() => {
            navigate("/create-shop")
          }}
          large
          title="Create your shop"
          key="get-started"
        />
      </div>
      <div className="relative flex flex-grow justify-end sm:basis-full">
        <div className="relative z-10">
          <StaticImage
            placeholder="none"
            alt="teaser Shop"
            src="../images/teaser-shop.png"
          />
        </div>

        <div className="absolute bottom-0 -left-60 hidden max-h-fit -translate-y-2 sm:block">
          <StaticImage
            placeholder="none"
            alt="teaser Shop"
            src="../images/teaser-path.png"
          />
        </div>
        <div className="absolute bottom-0 right-0 ">
          <StaticImage
            placeholder="none"
            alt="teaser Shop"
            src="../images/teaser-leaf.png"
          />
        </div>
      </div>
    </section>
  )
}

import React from "react"
import { Layout } from "../components"
import { CTA, Teaser, ShortInfo, SiteNote } from "../pageblocks"

const IndexPage = () => (
  <Layout>
    <Teaser />
    <ShortInfo />
    <CTA />
    <SiteNote />
  </Layout>
)

export default IndexPage

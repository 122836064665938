import React from "react"
import { Paragraph, Heading } from "../components"
import I1 from "../images/icon-section/i1.svg"
import I2 from "../images/icon-section/i2.svg"
import I3 from "../images/icon-section/i3.svg"
import I4 from "../images/icon-section/i4.svg"

export const ShortInfo = () => {
  return (
    <section className="lg:py-10">
      <div className="text-center">
        <Paragraph level={1} className="my-5" align="center">
          How it works
        </Paragraph>
        <Heading level={2} align="center">
          Mint, sell and repeat
        </Heading>
      </div>
      <div className="mt-20 flex flex-col items-center justify-center sm:flex-row sm:flex-wrap sm:justify-evenly">
        <div className="flex flex-col items-center gap-6">
          <I1 className="mx-auto h-16 w-16" />
          <Heading level={4} align="center" className="m-8">
            Your shop,
            <br />
            your style
          </Heading>
        </div>
        <div className="flex flex-col items-center gap-6">
          <I2 className="mx-auto h-16 w-16" />
          <Heading level={4} align="center" className="m-8">
            Support Near
            <br />
            Blockchain
          </Heading>
        </div>
        <div className="flex flex-col items-center gap-6">
          <I3 className="mx-auto h-16 w-16" />
          <Heading level={4} align="center" className="m-8">
            Easy to publish
            <br />
            your store
          </Heading>
        </div>
        <div className="flex flex-col items-center gap-6">
          <I4 className="mx-auto h-16 w-16" />
          <Heading level={4} align="center" className="m-8">
            Easy to manage
            <br />
            your NFTs
          </Heading>
        </div>
      </div>
    </section>
  )
}

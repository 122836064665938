import React from "react"

export const SiteNote = () => {
  return (
    <div className="py-14 text-center">
      <span>We do NOT collect any of your data </span>
      <a className="text-gorilla-purple underline" href="/privacy-policy">
        Learn more
      </a>
    </div>
  )
}

import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Button, Paragraph, Heading } from "../components"

export const CTA = () => {
  return (
    <section className="relative">
      <div className="absolute inset-0 sm:px-14">
        <StaticImage
          className="h-full w-full object-cover"
          src="../images/cta-background.jpeg"
          alt=""
        />
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-24 text-center sm:py-24 sm:px-6 lg:px-8">
        <Paragraph level={1} align="center">
          Set-up in 5 minutes - No basic fees - No contract
        </Paragraph>
        <Heading level={2} align="center" className="my-10">
          Set up your store with <br />
          Gorilla Shops today
        </Heading>
        <Button
          large
          onClick={() => {
            navigate("/create-shop")
          }}
          title="Create your shop"
        />
      </div>
    </section>
  )
}
